.App {
  background-image: url(./styles/img/desktop.jpg);
  background-size: cover;
  height: 1000px;
  max-height: 100%;
  position: relative;
  max-width: 100%;
}


/* .App-header {
  height: 25%;
  width: 75%;
  margin-top: 5px;
  background-color: #fff;
  background-image: none;
  color: #fa5858;
  align-content: center;
  position: absolute;
} */

.App-logo__box {
  
}

.App-logo {
  height: 125px;
  position: absolute;
  top: 1%;
  left: -50px;
}

.App-header-text {
  position: absolute;
  background-color:  #fa5858;
  color: #fff;
  width: 75%;
  padding: 20px;
  transform: translate(-50%, -50%);
  text-align: center;  
  border-radius: 200px;
  font-size: 24px;
  top: 4%;
  left: 55%;
}

.App-text-box {
  position: absolute;
  background-color:  #fff;
  color: #fa5858;
  width: 50%;
  padding: 50px;
  top: 20%;
  left: 55%;
  transform: translate(-50%, -50%);
  text-align: center;  
  border-radius: 200px;
  font-size: 24px;
  margin-top: 50px;
  opacity: .9;

}